import topic from '../topic';

export const dataAPI = {
    getALLPosis: topic.dataTopic + '/get_all_posis',
    countPush9001: topic.dataTopic + '/count_push_9001',
    countPush9002: topic.dataTopic + '/count_push_9002',
    countPush9003: topic.dataTopic + '/count_push_9003',
    countPush9004: topic.dataTopic + '/count_push_9004',
    count_push_9005: topic.dataTopic + '/count_push_9005',
    count_push_9007: topic.dataTopic + '/count_push_9007',
    count_push_9008: topic.dataTopic + '/count_push_9008',
    count_push_9009: topic.dataTopic + '/count_push_9009'
};
