<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group">
        <el-button type="primary" @click="submit('stffForm')" size="small">保存</el-button>
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
      <el-form ref="stffForm" :model="stffForm" :rules="rules" label-width="120px" size="mini" :show-message="false">
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="员工编号" prop="stff_no" required>
              <el-input v-model="stffForm.stff_no" maxlength="10" show-word-limit placeholder="请填写员工编号"></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="stff_name" required>
              <el-input v-model="stffForm.stff_name" maxlength="10" show-word-limit placeholder="请填写员工姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="stffForm.stff_identity" maxlength="18" show-word-limit placeholder="请填写身份证号"></el-input>
            </el-form-item>
            <el-form-item label="员工性别">
              <el-input v-model="stffForm.stff_gender" placeholder="根据身份信息自动填充" disabled> </el-input>
            </el-form-item>
            <el-form-item label="员工电话">
              <el-input v-model="stffForm.stff_phone" maxlength="30" placeholder="请填写电话" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="入职时间" prop="stffEnterTime" required>
              <el-date-picker
                type="date"
                placeholder="请选择入职时间"
                v-model="stffForm.stffEnterTime"
                @change="deleteEnterTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="离职时间" prop="stffLeaveTime">
              <el-date-picker
                type="date"
                placeholder="请选择离职时间"
                :picker-options="pickerOptions"
                v-model="stffForm.stffLeaveTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="员工生日">
              <el-input v-model="stffForm.stff_birthday" placeholder="根据身份信息自动填充" disabled></el-input>
            </el-form-item>
            <el-form-item label="员工年龄" prop="stff_age">
              <el-input v-model="stffForm.stff_age" placeholder="根据身份信息自动填充" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="公司抬头" prop="cptt_id" :rules="[{ required: true }]">
              <el-select
                v-model="stffForm.cptt_id"
                placeholder="请选择公司抬头"
                clearable
                @visible-change="getCpttV1($event)"
                @change="selectCpttName"
              >
                <el-option v-for="item in cpttGroupOpt" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门名称" prop="dept_id" :rules="[{ required: true }]">
              <el-select
                v-model="stffForm.dept_id"
                placeholder="请选择部门"
                clearable
                @visible-change="getDeptV1($event)"
                @change="openTeam"
              >
                <el-option v-for="item in deptGroupOpt" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组名称">
              <el-select
                v-model="stffForm.dept_team_id"
                placeholder="请选择小组（部门未选时禁选）"
                clearable
                @visible-change="getTeamByDept($event)"
                :disabled="disabled"
              >
                <el-option v-for="item in teamGroupOpt" :key="item.dept_team_id" :label="item.dept_team_name" :value="item.dept_team_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工岗位">
              <el-select
                v-model="stffForm.stff_job"
                maxlength="10"
                placeholder="请选择岗位"
                filterable
                clearable
                @visible-change="getStffJob($event)"
                allow-create
              >
                <el-option v-for="item in stffJobGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上级领导">
              <select-input
                v-if="selectFlag"
                @refreshClick="refreshClick"
                :selTableData="selTableData"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_leader_name"
                :disabled="disabled2"
                :isShowCol="false"
                :rowSelect="rowSelect"
                placeholder="请选择上级领导"
              >
              </select-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import { dataAPI } from '@api/modules/data';
import { imgeAPI } from '@api/modules/imge';
import selectInput from '@/views/component/selectInput';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import options from '@/views/component/common/options';
import helper from '@assets/js/helper';
import { optnAPI } from '@api/modules/optn';
export default {
  name: 'StffAdd.vue',
  components: {
    selectInput
  },
  data() {
    return {
      stffForm: {
        stff_no: '',
        stff_name: '',
        stff_leader: null,
        stff_leader_name: '',
        cptt_id: null,
        dept_id: null,
        dept_team_id: null,
        stffEnterTime: null,
        stffLeaveTime: null,
        stff_gender: '',
        stffBirthday: null,
        stff_phone: '',
        stff_identity: '',
        stff_education: '',
        stff_school: '',
        stff_major: '',
        stff_address: '',
        stff_job: '',
        stff_place: '',
        stff_place_address: '',
        stff_leave_reason: '',
        stff_remark: '',
        imge_id: null
      },
      rowSelect: [],
      key: 0,
      compTitle: '',
      disabled: true,
      disabled2: false,
      merryDisabled: false,
      selTableData: [],
      cpttGroupOpt: [],
      deptGroupOpt: [],
      teamGroupOpt: [],
      stffJobGroupOpt: [], // 员工岗位
      selectionsList: [],
      staffTableHeader: [],
      rules: {},
      pickerOptions: this.beginDate(),
      selectFlag: true
    };
  },
  created() {
    this.getSelectTable();
    this.staffTableHeader = staffTableHeader;
  },
  watch: {
    'stffForm.dept_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.stffForm.dept_team_id = null;
        this.teamGroupOpt = [];
      }
    },
    'stffForm.stff_marry': function (newVal) {
      if (newVal === '未婚未育' || newVal === '已婚未育') {
        this.merryDisabled = true;
      } else {
        this.merryDisabled = false;
      }
    },
    'stffForm.stff_identity': function (newVal) {
      this.stffForm.stff_age = this.helper.mathUserInfo(newVal, 3);
      this.stffForm.stff_birthday = this.helper.mathUserInfo(newVal, 1);
      this.stffForm.stff_gender = this.helper.mathUserInfo(newVal, 2);
    }
  },
  methods: {
    // 离职时间控制关闭选项
    beginDate() {
      let that = this;
      return {
        disabledDate(time) {
          return time.getTime() < that.stffForm.stffEnterTime;
        }
      };
    },
    refreshClick() {
      this.getSelectTable();
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.selTableData = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm.stff_leader = val.stff_id;
      this.stffForm.stff_leader_name = val.stff_name;
    },
    // 获取员工岗位
    getStffJob(flag) {
      if (flag === true && this.stffJobGroupOpt.length === 0) {
        get(optnAPI.getOptnByPermId, { perm_id: 10001 })
          .then(res => {
            if (res.data.code === 0) {
              this.stffJobGroupOpt = res.data.data.form.optn_cntt_list;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const stffFormBody = this.stffForm;
      stffFormBody.stff_enter_time = Number(new Date(this.stffForm.stffEnterTime).getTime() / 1000);
      stffFormBody.stff_leave_time = Number(new Date(this.stffForm.stffLeaveTime).getTime() / 1000);
      post(stffAPI.addStff, stffFormBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.resetForm('stffForm');

            this.stffForm.imge_id = null;
            this.jump('/stff_edit', {
              perm_id: this.$route.query.perm_id,
              form_id: res.data.data.form_id
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('stffForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(res => {});
    },
    //表单重置
    resetForm(formName) {
      this.selectFlag = false;
      this.$refs[formName].resetFields();
      this.stffForm.stff_identity = '';
      this.stffForm.stff_phone = '';
      this.stffForm.stff_gender = null;
      this.stffForm.stff_birthday = null;
      this.stffForm.stff_job = null;
      this.stffForm.stff_leader_name = null;
      this.$nextTick(() => {
        this.selectFlag = true;
      });
    },
    // 获取cpttId
    getCpttV1(flag) {
      if (flag === true && this.cpttGroupOpt.length === 0) {
        get(cpttAPI.getAllCpttsV1)
          .then(res => {
            if (res.data.code === 0) {
              this.cpttGroupOpt = res.data.data;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1).then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取deptTeamId
    getTeamByDept(flag) {
      if (flag === true && this.teamGroupOpt.length === 0) {
        get(deptAPI.getTeamsByDeptId, { dept_id: this.stffForm.dept_id })
          .then(res => {
            if (res.data.code === 0) {
              this.teamGroupOpt = res.data.data;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取公司的抬头
    selectCpttName(params) {
      const parObj = this.cpttGroupOpt.find(item => {
        return item.cptt_id === params;
      });

      if (parObj) {
        this.compTitle = parObj.cptt_name;
      }
    },
    //开启选择小组
    openTeam(val) {
      if (val) {
        this.stffForm.dept_id = val;
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    // 清除离职时间
    deleteEnterTime() {
      if (this.stffForm.stffEnterTime > this.stffForm.stffLeaveTime) {
        this.stffForm.stffLeaveTime = null;
      }
    },
    //姓名只可以输入中文
    nameChange(event) {
      this.stffForm.stff_name = this.helper.keepChin(event);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
</style>
